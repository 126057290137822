<template  lang="pug">
	v-card
		v-dialog(v-model='dialog' max-width='500px' persistent)
			v-card
				v-card-title Producto
				v-card-text
					v-form(ref="form")
						v-row
							v-col(cols='12' sm='6' md='4')
								v-text-field( v-model='editedItem.sku'  type="number" min="1" label='SKU' :rules="requiredRules")

							v-col(cols='12' sm='6' md='4')
								v-menu(v-model='menu1' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='auto')
									template(v-slot:activator='{ on, attrs }')
										v-text-field(
											v-model='editedItem.fecha' 
											label='Fecha' :rules="requiredRules"  readonly='' v-bind='attrs' v-on='on')
									v-date-picker(v-model='editedItem.fecha' @input='menu1 = false')
							v-col(cols='12' sm='6' md='4')
								v-text-field( 
									:rules="requiredRules"
									label='idu_departamentocodigo'
									type="number" min="1"
									v-model="editedItem.idu_departamentocodigo")
							v-col(cols='12' sm='6' md='4')
								v-text-field( 
									:rules="requiredRules"
									label='idu_clasecodigo'
									type="number" min="1"
									v-model="editedItem.idu_clasecodigo")
							v-col(cols='12' sm='6' md='4')
								v-text-field( 
									:rules="requiredRules"
									label='idu_familiacodigo'
									type="number" min="1"
									v-model="editedItem.idu_familiacodigo")
							v-col(cols='12' sm='6' md='4')
								v-text-field(
									:rules="requiredRules"
									label='idu_areacodigo'
									type="number" min="2" max="3" 
									v-model="editedItem.idu_areacodigo")
							v-col(cols='12' sm='6' md='4')
								v-text-field(
									:rules="requiredRules"
									label='des_departamento'
									v-model="editedItem.des_departamento")
							v-col(cols='12' sm='6' md='4')
								v-text-field(
									:rules="requiredRules"
									label='des_clase'
									v-model="editedItem.des_clase")
							v-col(cols='12' sm='6' md='4')
								v-text-field(
									:rules="requiredRules"
									label='des_familia'
									v-model="editedItem.des_familia")
							v-col(cols='12' sm='6' md='4')
								v-menu(v-model='menu2' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='auto')
									template(v-slot:activator='{ on, attrs }')
										v-text-field(
											v-model='editedItem.fecha_inicio' 
											label='Fecha inicio' :rules="requiredRules"  readonly='' v-bind='attrs' v-on='on')
									v-date-picker(v-model='editedItem.fecha_inicio' @input='menu2 = false')

							v-col(cols='12' sm='6' md='4')
								v-menu(v-model='menu3' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='auto')
									template(v-slot:activator='{ on, attrs }')
										v-text-field(v-model='editedItem.fecha_fin' label='Fecha fin' :rules="requiredRules"  readonly='' v-bind='attrs' v-on='on')
									v-date-picker(v-model='editedItem.fecha_fin' @input='menu3 = false')

				v-card-actions
					v-spacer
					v-btn(color='secondary' text     :disabled="loading" @click='close') Cancelar
					v-btn(color='blue darken-1' text :loading="loading" @click='create_product')  Guardar
		v-row(justify="end" align="center")
			v-col(cols="12" sm="4" )
				v-form(ref="formSku")
					v-file-input(
						v-model="filesSKU"
						accept=".csv"
						label="Elegir archivo"
						:rules="rulesFile"
					)
			v-col(cols="12" sm="2" )
				v-btn(color="blue-500" class="white--text mr-3" :loading="loading" @click="loadFile") cargar
		v-card-title Productos
			v-spacer
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details)
			v-btn( dark x-small fab  depressed class="ml-5" color="blue-500" @click="dialog = true"  class="white--text")
				v-icon mdi-plus
			v-btn( dark x-small fab  text @click="openUrl")
				v-icon(color="yellow-600"  size="30px" class="ml-5" ) mdi-cloud-download
		v-data-table(
			:headers="headers"
			:items="transactions"
			:search="search"
			:loading="loading"
			:items-per-page="10"
			class="elevation-0")
								
</template>
<script>
	import XLSX from 'xlsx'

	import ApiService from '../../../services/ApiService'
	const apiService = new ApiService();

	export default {
		name: 'RankingProducts',

		data() {
			return {
				menu1 : false,
				menu2 : false,
				menu3 : false,

				search		 : "",
				transactions : [],
				dialog 		 : false,
				loading 	 : false,
				filesSKU 	 : [],
				
				editedItem: {
					sku: 0,
					fecha: '',
					idu_departamentocodigo: 0,
					idu_clasecodigo: 0,
					idu_familiacodigo: 0,
					idu_areacodigo: 2,
					des_departamento: '',
					des_clase: '',
					des_familia: '',
					fecha_inicio: '',
					fecha_fin: '',

				},
				defaultItem: {
					sku: 0,
					fecha: '',
					idu_departamentocodigo: 0,
					idu_clasecodigo: 0,
					idu_familiacodigo: 0,
					idu_areacodigo: 2,
					des_departamento: '',
					des_clase: '',
					des_familia: '',
					fecha_inicio: '',
					fecha_fin: '',
				},

				//- second tab
				headers: [
					{ 
						text: 'SKU',
						value: 'sku',
					},
					{ 
						text: 'Fecha',
						value: 'fecha',
					},
					{ 
						text: 'idu_departamento', 
						value: 'idu_departamentocodigo',
					},
					{ 
						text: 'idu_clase', 
						value: 'idu_clasecodigo',
					},
					{ 
						text: 'idu_familia', 
						value: 'idu_familiacodigo',
					},
					{ 
						text: 'idu_area', 
						value: 'idu_areacodigo',
					},
					{ 
						text: 'departamento', 
						value: 'des_departamento', 
					},
					{ 
						text: 'clase', 
						value: 'des_clase',
					},
					{
						text: 'familia', 
						value: 'des_familia',
						 
					},
					{
						text: 'Fecha inicio', 
						value: 'fecha_inicio',
					},
					{
						text: 'Fecha fin',
						value: 'fecha_fin',
					},
					
					//- { text: 'Operacciones', value: 'action', align: "center", sortable: false },
				],

				requiredRules: [
					v => !!v || 'Este campo es necesario',
				],
				rulesFile: [
					v => !v || 'Este campo es necesario',
				],
			}
		},
		mounted(){
			this.fetchTransactions()
		},
		methods:{
			fetchTransactions:function(){
				this.loading = true;

				apiService.ranking_products_index()
				.then((response)=>{
					this.transactions = response.data.data
					this.loading = false;

				})
				.catch((error)=>{
					console.log("Error: No connect with management ");
					this.loading = false;
				})

			},
			create_product: function(){
				this.loading = true;

				if (!this.$refs.form.validate()) {
					this.loading = false;
					console.log("no valid");
					return ;
				}

				let formData = new FormData();
				for ( var key in this.editedItem ) {
					formData.append(key, this.editedItem[key]);
				}

				apiService.ranking_products_store(formData)
				.then((response)=>{
					this.transactions = response.data.data
					this.loading = false;
					this.close();

				})
				.catch((error)=>{
					console.log("Error: No connect with management");
					this.loading = false;
				})
			},
			editItem (item) {
				let data = {}
				this.editedItem = Object.assign({}, data)
				this.dialog = true
			},
			close () {
				this.dialog = false;
				this.editedItem = Object.assign({}, this.defaultItem);
			},
			openUrl: function(){
				// export json to Worksheet of Excel
				// only array possible
				var animalWS = XLSX.utils.json_to_sheet(this.transactions)

				// A workbook is the name given to an Excel file
				var wb = XLSX.utils.book_new() // make Workbook of Excel

				// add Worksheet to Workbook
				// Workbook contains one or more worksheets
				XLSX.utils.book_append_sheet(wb, animalWS, 'productos') // sheetAName is name of Worksheet
				XLSX.utils.sheet_to_csv();

				// export Excel file
				XLSX.writeFile(wb, 'ranking_productos.csv') // name of the file is 'book.xlsx'
			},
			loadFile: function(){
				this.loading = true;
				if(!this.$refs.formSku.validate()){
					this.loading = false;
					console.log("no valid");
					return;
				}
					
				
				let formData = new FormData();

				formData.append("files", this.filesSKU);
				let config = {
					headers : {
						'Content-Type' : 'multipart/form-data',
					}
				}
				apiService.ranking_products_csv(formData)
				.then((response)=>{
					this.categoria = -1;
					// this.files = [];
					if(response.data.status=='success'){
						
						this.transactions = response.data.data
						this.loading = false;
					}

				})
				.catch((error)=>{
					console.log("Error: No connect with recommender ")
				})
				.then((response)=>{
					this.loading = false;
				})
			},
		},
		
	
	}
</script>
