import BackendAuthService from "./BackendAuthService";

export default class ApiService extends BackendAuthService {
	
	constructor() {
		super();
		this._path 		= 'interfaz/';
	}
	
	// CATEGORIES RANKIN
	ranking_caegories_index() {
		return this.httpCall(this._path +'ranking/category', {
			method: 'GET',
		});
	}

	ranking_caegories_update(data, id) {
		return this.httpCall(this._path+'ranking/category/' +id, {
			method: 'POST',
			data
		});
	}
	// PRODUCTS RANKIN
	ranking_products_index() {
		return this.httpCall(this._path +'ranking/products', {
			method: 'GET',
		});
	}

	ranking_products_store(data) {
		return this.httpCall(this._path+'ranking/products/store', {
			method: 'POST',
			data
		});
	}

	ranking_products_update(data, id) {
		return this.httpCall(this._path+'ranking/products/' +id, {
			method: 'POST',
			data
		});
	}

	ranking_products_csv(data) {
		return this.httpCall(this._path+'ranking/products-csv', {
			method: 'POST',
			data: data,
		});
	}

}
