<template  lang="pug">
	v-container(class="section-pim")
		v-row(justify="center")
			//- choose opcion
			v-col( cols="12" lg="12" )
				v-tabs(
						background-color="blue-600"
						color="white"
						right)
					v-tab 
						label(class="d-none d-sm-flex") Categorias 
						v-icon(class="hidden-sm-and-up") mdi-priority-high
					v-tab
						label(class="d-none d-sm-flex") Productos
						v-icon(class="hidden-sm-and-up") mdi-file-upload
					v-tab-item
						ranking-categories
					v-tab-item
						ranking-products
								
</template>
<script>
	import XLSX from 'xlsx';

	import ApiService from '../../services/ApiService';
	const apiService = new ApiService();
	import RankingProducts  from './sections/RankingProducts.vue';
	import RankingCategories  from './sections/RankingCategories.vue';
	export default {
		name: 'Ranking',

		components: {
			'ranking-products' : RankingProducts,
			'ranking-categories' : RankingCategories
		},
		data() {
			return {
			}
		},
		mounted(){
		},
		methods:{
		},
		
	
	}
</script>
