<template  lang="pug">
	v-card
		v-row(justify="center" aling="center")
			v-col(class="")
				p(class="text-caption primary--text" color="primary") Nota: El orden de las clases afecta a todas las búsquedas.
		v-card-title Categorías
			v-spacer
			v-text-field(
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details)
			v-btn(text @click="openUrl")
					v-icon(
						color="yellow-600"
				) mdi-cloud-download
		v-data-table(
			:headers="headers"
			:items="transaction"
			:search="search"
			:items-per-page="10"
			:loading="loading"
			class="elevation-0")
			template( v-slot:top)
				v-dialog(v-model='dialog' max-width='500px' persistent)
					v-card
						v-card-title Categoria
						v-card-text
							v-form(ref="form")
								v-row
									v-col(cols='12' sm='6' md='4')
										v-text-field( 
											:rules="requiredRules"
											label='Actual posición' 
											disabled 
											v-model="editedItem.posicionsugerida")
									v-col(cols='12' sm='6' md='4')
										v-text-field(
											:rules="requiredRules"
											label='Nueva posición'
											type="number" min="1"
											v-model="editedItem.nuevaposicion")
						v-card-actions
							v-spacer
							v-btn(color='secondary' text     :disabled="loading" @click='close') Cancelar
							v-btn(color='blue darken-1' text :loading="loading" @click='update')  Guardar
			template( v-slot:item.action="{ item }" align="center")
				v-btn( icon class="ma-2" color="blue-500" @click="editItem(item)")
					v-icon mdi-pencil
</template>
<script>
	import XLSX from 'xlsx';

	import ApiService from '../../../services/ApiService';
	const apiService = new ApiService();
	export default {
		name: 'RankingCategories',

		data() {
			return {
				search:"",
				transaction: [],
				dialog: false,
				loading:false,
				
				headers: [
					{ text: 'Posicion sugerida', value: 'posicionsugerida'},
					{ text: 'des_area', value: 'des_area' },
					{ text: 'des_departamento', value: 'des_departamento' },
					{ text: 'des_clase', value: 'des_clase' },
					{ text: 'idu_areacodigo', value: 'idu_areacodigo' },
					{ text: 'idu_departamentocodigo', value: 'idu_departamentocodigo' },
					{ text: 'idu_clasecodigo', value: 'idu_clasecodigo' },
					{ text: 'fecha', value: 'fecha' },
					{ text: 'Acciones', value: 'action', sortable: false },
				],
				editedItem: {
					id: '',
					posicionsugerida: '',
					nuevaposicion: '',
				},
				defaultItem: {
					id: '',
					posicionsugerida: '',
					nuevaposicion: '',
				},


				requiredRules: [
					v => !!v || 'Este campo es necesario',
				],
				rulesImg: [
					v => !v.length || 'Este campo es necesario',
				],
			}
		},
		mounted(){
			this.fetchTransactions()
		},
		methods:{
			fetchTransactions:function(){
				this.loading = true;

				apiService.ranking_caegories_index()
				.then((response)=>{
					this.transaction = response.data.data
					this.loading = false;

				})
				.catch((error)=>{
					console.log("Error: No connect with management ");
					this.loading = false;
				})

			},
			update: function(item){
				this.loading = true;
				if (!this.$refs.form.validate()) {
					this.loading = false;
					return;
				}

				let formData = new FormData();
				formData.append("id", this.editedItem.id);
				formData.append("posicionsugerida", this.editedItem.posicionsugerida);
				formData.append("nuevaposicion", this.editedItem.nuevaposicion);
				let nuevaposicion = this.transaction.find(element => element.posicionsugerida == this.editedItem.nuevaposicion)
				formData.append("id_nuevaposicion", nuevaposicion.id);

				apiService.ranking_caegories_update(formData, this.editedItem.posicionsugerida)
				.then((response)=>{
					this.transaction = response.data.data
					this.loading = false;
					this.close();

				})
				.catch((error)=>{
					console.log("Error: No connect with management");
					this.loading = false;
				})
			},
			editItem (item) {
				let data = {
					"id": item.id,
					"posicionsugerida": item.posicionsugerida,
					"nuevaposicion": '',
				}
				this.editedItem = Object.assign({}, data)
				this.dialog = true
			},
			close () {
				this.dialog = false;
				this.editedItem = Object.assign({}, this.defaultItem);
			},
			openUrl: function(){
				// export json to Worksheet of Excel
				// only array possible
				var animalWS = XLSX.utils.json_to_sheet(this.transaction)

				// A workbook is the name given to an Excel file
				var wb = XLSX.utils.book_new() // make Workbook of Excel

				// add Worksheet to Workbook
				// Workbook contains one or more worksheets
				XLSX.utils.book_append_sheet(wb, animalWS, 'categorias') // sheetAName is name of Worksheet
				XLSX.utils.sheet_to_csv();

				// export Excel file
				XLSX.writeFile(wb, 'ranking_categorias.csv') // name of the file is 'book.xlsx'
				
			}
		},
		
	
	}
</script>
